.event-stat {
  display: flex;
  flex-direction: column;
  gap: 3em;

  margin: 0.5em;
  margin-left: 2em;

  .title {
    font-size: 1.5em;
    margin: 2em 0;
  }

  table {
    max-width: 90%;
    width: 50em;
    margin: 0 auto;
    border-collapse: collapse;

    tr {
      border: 1px solid grey;
    }

    th {
      font-size: 1.1em;
    }

    th,
    td {
      text-align: center;
      padding: 0.5em;
    }
  }

  .empty {
    font-size: 1.5em;
    margin: 3em auto;
  }
}
